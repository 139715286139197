import { REPEAT_END_TYPE, REPEAT_TYPE } from "@/services/enums/commonEnum";
import {
  DirectOrderTrip,
  DIRECTS_ORDER_FIELDS,
  RepeatTripConfig,
} from "@/stores/direct-order/types";
import {
  daysToMilliseconds,
  findWeekdaysInOccurrence,
  findWeekdaysInRange,
  isBeforeOrEqual,
} from "@/utils/time.utils";
import { generateId } from "@/utils/utils";
import { addDays, addMilliseconds } from "date-fns";
import { cloneDeep } from "lodash";

/**
 * Generates daywise repeated trips based on the given configuration.
 * It creates new trips by updating the departure and arrival times
 * according to the repeat configuration, and either stops after a
 * certain end date or a set number of occurrences.
 * @param {DirectOrderTrip} trip - The original trip object to be repeated.
 * @param {RepeatTripConfig} repeatConfig - Configuration specifying
 *  the repeat criteria (start date, repeat interval, end condition, etc.).
 * @returns {Object.<string, DirectOrderTrip>} An object where the keys are
 *  new trip IDs, and the values are the newly generated repeated trips.
 */
const executeDaywiseRepeatTrips = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
) => {
  //implement days logic
  const { startDate, dayOrWeekCount, endDate, occurence, selectedEndOption } =
    repeatConfig;

  let repeatTrips = {};

  let newTripDepartureDate = new Date(
    `${startDate.toDateString()} ${new Date(
      trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]
    ).toTimeString()}`
  );

  let diffWithOriginalTrip =
    newTripDepartureDate.valueOf() -
    new Date(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]).valueOf();

  if (selectedEndOption === REPEAT_END_TYPE.ON_END_DATE) {
    while (isBeforeOrEqual(newTripDepartureDate, endDate)) {
      const updatedRoutePoints = cloneDeep(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS]
      );

      updatedRoutePoints.oneway[0].planned_departure_time =
        newTripDepartureDate;

      const newTrip: DirectOrderTrip = {
        ...trip,
        id: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]:
          newTripDepartureDate.toString(),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: {
          ...trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME],
          oneway: addMilliseconds(
            new Date(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME].oneway),
            diffWithOriginalTrip
          ).toString(),
        },
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      repeatTrips = { ...repeatTrips, [newTrip.id]: newTrip };

      newTripDepartureDate = addDays(newTripDepartureDate, dayOrWeekCount);

      trip = newTrip;
      diffWithOriginalTrip = daysToMilliseconds(dayOrWeekCount);
    }
  } else if (selectedEndOption === REPEAT_END_TYPE.AFTER_OCCURENCE) {
    let occCount = 1;

    while (occCount <= occurence) {
      const updatedRoutePoints = cloneDeep(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS]
      );

      updatedRoutePoints.oneway[0].planned_departure_time =
        newTripDepartureDate;

      const newTrip: DirectOrderTrip = {
        ...trip,
        id: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]:
          newTripDepartureDate.toString(),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: {
          ...trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME],
          oneway: addMilliseconds(
            new Date(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME].oneway),
            diffWithOriginalTrip
          ).toString(),
        },
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      repeatTrips = { ...repeatTrips, [newTrip.id]: newTrip };

      newTripDepartureDate = addDays(newTripDepartureDate, dayOrWeekCount);

      trip = newTrip;
      diffWithOriginalTrip = daysToMilliseconds(dayOrWeekCount);

      occCount++;
    }
  }
  return repeatTrips;
};

/**
 * Generates repeated trips based on the weekly repetition configuration provided.
 * This function supports generating trips either up to a certain end date or based on a set occurrence count.
 * The new trips are created by adjusting the dates of the original trip while maintaining other properties.
 * @param {DirectOrderTrip} trip - The original trip object that serves as the template for creating repeated trips.
 * @param {RepeatTripConfig} repeatConfig - The configuration object containing information on how the trips should be repeated.
 * @returns {Record<string, DirectOrderTrip>} A record of newly created trips, where each trip ID maps to a DirectOrderTrip object.
 */
const executeWeeklyRepeatTrips = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
) => {
  //implement weeks logic

  const {
    startDate,
    dayOrWeekCount,
    endDate,
    selectedDays,
    occurence,
    selectedEndOption,
  } = repeatConfig;

  let repeatTrips = {};

  const diffWithOriginalTrip =
    new Date(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME].oneway).valueOf() -
    new Date(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]).valueOf();

  if (selectedEndOption === REPEAT_END_TYPE.ON_END_DATE) {
    const dayDates = findWeekdaysInRange(
      startDate,
      endDate,
      selectedDays,
      dayOrWeekCount
    );

    dayDates.forEach((currentDate) => {
      const currentDateWithUpdatedTime = new Date(
        `${currentDate.toDateString()} ${new Date(
          trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]
        ).toTimeString()}`
      );
      const updatedRoutePoints = cloneDeep(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS]
      );
      updatedRoutePoints.oneway[0].planned_departure_time =
        currentDateWithUpdatedTime;
      const newTrip: DirectOrderTrip = {
        ...trip,
        id: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]:
          currentDateWithUpdatedTime.toString(),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: {
          ...trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME],
          oneway: addMilliseconds(
            currentDateWithUpdatedTime,
            diffWithOriginalTrip
          ).toString(),
        },
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      repeatTrips = { ...repeatTrips, [newTrip.id]: newTrip };
    });
  } else {
    const dayDates = findWeekdaysInOccurrence(
      startDate,
      selectedDays,
      occurence,
      dayOrWeekCount
    );

    dayDates.forEach((currentDate) => {
      const currentDateWithUpdatedTime = new Date(
        `${currentDate.toDateString()} ${new Date(
          trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]
        ).toTimeString()}`
      );
      const updatedRoutePoints = cloneDeep(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS]
      );
      updatedRoutePoints.oneway[0].planned_departure_time =
        currentDateWithUpdatedTime;
      const newTrip: DirectOrderTrip = {
        ...trip,
        id: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]:
          currentDateWithUpdatedTime.toString(),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: {
          ...trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME],
          oneway: addMilliseconds(
            currentDateWithUpdatedTime,
            diffWithOriginalTrip
          ).toString(),
        },
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      repeatTrips = { ...repeatTrips, [newTrip.id]: newTrip };
    });
  }

  return repeatTrips;
};

/**
 * Generates repeat trips based on a specified configuration.
 *
 * This function creates new trips by repeating an existing trip according to the
 * repeat configuration, which includes options for repeating by day or week, ending
 * on a specific date, or after a certain number of occurrences.
 * @param {DirectOrderTrip} trip - The original trip object containing details such as departure, arrival times, and route points.
 * @param {RepeatTripConfig} repeatConfig - Configuration object specifying the repetition criteria.
 * @param {Date} repeatConfig.startDate - The start date for repeating trips.
 * @param {number} repeatConfig.dayOrWeekCount - Number of days or weeks between each repeated trip.
 * @param {string} repeatConfig.dayOrWeek - The type of repetition: either "DAYWISE" or "WEEKLY".
 * @param {Array<number>} repeatConfig.selectedDays - The selected days of the week for weekly repetitions.
 * @param {Date} repeatConfig.endDate - The end date to stop repeating trips.
 * @param {number} repeatConfig.occurence - The number of times the trip should repeat.
 * @param {string} repeatConfig.selectedEndOption - The end condition: "ON_END_DATE" or "AFTER_OCCURENCE".
 * @returns {object} - A collection of repeated trips keyed by their IDs, or null if no trips are created.
 */
export const executeRepeatTrip = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
) => {
  const { dayOrWeekCount, dayOrWeek } = repeatConfig;

  let repeatTrips = null;

  if (dayOrWeekCount > 0) {
    if (dayOrWeek === REPEAT_TYPE.DAYWISE) {
      repeatTrips = executeDaywiseRepeatTrips(trip, repeatConfig);
    }
    if (dayOrWeek === REPEAT_TYPE.WEEKLY) {
      repeatTrips = executeWeeklyRepeatTrips(trip, repeatConfig);
    }
  }
  return repeatTrips;
};
